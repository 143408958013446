import React from "react";
import { Link } from "gatsby";

const HowWeHelp = ({ children, author, place, image }) => (
  <div className="section functions opinion-section">
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="main-desc opinion-wrapper">
            <h2>Zobacz, jak wielu placówkom pomogliśmy</h2>
            <p className="normal-desc">
              Zarządzanie przedszkolem jeszcze nigdy nie było tak proste.
              Przekonało się o tym wiele placówek i rodziców, którzy obecnie nie
              wyobrażają sobie swoich przedszkoli bez kidsup.
            </p>
            <Link to={`/opinie`}>
              <button className="violet-btn btn opinion-btn">
                Przeczytaj opinie właścicieli, dyrektorów i rodziców
              </button>
            </Link>

            <div className="carousel slide" data-ride="carousel">
              <div role="listbox">
                <div className="item active card">
                  <div className="row main-opinion-carousel-wrapper">
                    <div className="col-md-4 hidden-xs hidden-sm">
                      <i
                        style={{
                          backgroundImage: `url(${image})`,
                        }}
                      ></i>
                    </div>
                    <div className="col-md-8 col-sm-12">
                      <div className="main-opinion-carousel-wrapper-inner">
                        <div className="main-opinion-carousel">{children}</div>
                        <div className="main-opinion-carousel-author">
                          <span className="bold-author">{author}</span>–{place}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HowWeHelp;
