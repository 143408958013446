export const opinions = [
  {
    node: {
      strapiId: 1,
      author: "Alina Kaźmierczak",
      opinion:
        "Przede wszystkim szybki i uproszczony kontakt z rodzicami. Wysyłam jedną wiadomość i wiem, że trafi ona do każdego zarejestrowanego rodzica. Taka forma komunikacji sprawdziła się także podczas wiosennego nauczania zdalnego. Jako dyrektor miałam nadzór nad tym, jakie informacje i zadania zostały przekazane rodzicom.",
      place: "Dyrektor w Przedszkolu Amica Kids",
      image: {
        childImageSharp: {
          fluid: {
            src:
              "/static/cb85f73a979f42a8e44bcfaf9b55d3af/f0719/8cd7ad1f35c72500d39a2a1f5248aa1e.jpg",
          },
        },
      },
    },
  },
  {
    node: {
      strapiId: 2,
      author: "Justyna Piątkowska",
      opinion:
        "Jako dyrektor przedszkola z czystym sumieniem mogę polecić aplikacje Kidsup. Aplikacja jest doskonałą formą kontaktu między przedszkolem, nauczycielami i rodzicami. Służy nam do przekazywania informacji na bieżąco, informowania o wysokości opłat za przedszkole. Bardzo fajną formą jest Tablica na której możemy umieszczać zdjęcia z życia przedszkola, dodawać informacje od wychowawców każdej grupy.",
      place: "Dyrektor Prywatnego Przedszkola Eko-Raj",
      image: {
        childImageSharp: {
          fluid: {
            src:
              "/static/f9547b51caa24eced8156e6418df37bf/f0719/77ebfda179be9bd697ebd11179c0c958.jpg",
          },
        },
      },
    },
  },
  {
    node: {
      strapiId: 3,
      author: "Wanda Tchoryk",
      opinion:
        "Dzięki tej aplikacji bardzo poprawiła się komunikacja z rodzicami. Nasza komunikacja przyspieszyła. Dla nas kidsup również działa jako system komunikacji ze wszystkimi pracownikami przedszkola. Myślę, że w trudnym obecnie czasie, gdybyśmy musieli prowadzić naukę zdalną, ta aplikacja znacznie ułatwiłaby pracę nauczycielkom.",
      place: " Dyrketor Przedszkola Publicznego nr 4 w Bielawie ",
      image: {
        childImageSharp: {
          fluid: {
            src:
              "/static/67eea9189a20157a61c62a302fc1fd42/f0719/0be8f3e162d60e1a8c7338ef5a6225e1.jpg",
          },
        },
      },
    },
  },
  {
    node: {
      strapiId: 5,
      author: "Monika Borkowska",
      opinion:
        "Aplikacja kidsup zastąpiła nam zeszyty informacji. Jest prosta i szybka. Rodzice na bieżąco otrzymują wiadomości od wychowawców i ważne komunikaty z placówki. ",
      place: "wicedyrektor w ZSP w Bytyniu",
      image: {
        childImageSharp: {
          fluid: {
            src:
              "/static/6121479bef69cf3815b81f1c066a730b/f0719/61fab4c8cafc5fba924c6f8d65111343.jpg",
          },
        },
      },
    },
  },
  {
    node: {
      strapiId: 6,
      author: "Renata Kijek",
      opinion:
        "kidsup bardzo szybko połączył nas z rodzicami, którzy mają ograniczony dostęp do placówki. Od teraz każdy ma placówkę pod ręką i zagląda kiedy chce.",
      place: "Dyrektor w Przedszkolu Publicznym Bajeczka Nr 1",
      image: {
        childImageSharp: {
          fluid: {
            src:
              "/static/d96931bd5ee122193164a7c8bd557716/f0719/41c67931a79c07c4db3716cfb5c92ba0.jpg",
          },
        },
      },
    },
  },
  {
    node: {
      strapiId: 8,
      author:
        'Natalia Wiśniewska - Językowo-Sportowe Niepubliczne Przedszkole i Żłobek "Mali Giganci"',
      opinion:
        "Z czystym sumieniem polecam aplikacje kidsup. Zdecydowanie był to dobry wybór! Ułatwiła ona kontakt miedzy dyrektorem, nauczycielami a rodzicami co w obecnej sytuacji jest bardzo ważne. Dodatkowo plusem jest łatwość z jaka wystawia się faktury. System sam wszystko wylicza i powiadamia rodzica o pojawieniu się nowej płatności. ",
      place: null,
      image: {
        childImageSharp: {
          fluid: {
            src:
              "/static/139ae573949a79fa988363862ed91e6f/53f65/c7f78990dcaf77aebc1219bd32d020c5.png",
          },
        },
      },
    },
  },
];
