import React from "react";
import { Link } from "gatsby";

const PurpleRow = ({ children }) => (
  <div className="section section-purple no-padding">
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="main-desc">
            <h2>{children}</h2>
            <div className="section-purple-buttons">
              <Link to={`/zamow`}>
                <button className="pink-btn btn join-btn">
                  Wypróbuj bezpłatnie
                </button>
              </Link>
              <Link to={`/kontakt`}>
                <button className="white-btn btn join-btn">
                  Napisz do nas
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PurpleRow;
