import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";

import PurpleRow from "../components/purpleRow";
import HowWeHelp from "../components/howWeHelp";
import MacBook from "../assets/images/MacBookPro-2.png";
import IPhone from "../assets/images/iphone-kid.png";

import Talk from "../assets/images/icons/talk.svg";
import Money from "../assets/images/icons/money.svg";
import Person from "../assets/images/icons/person.png";
import { opinions } from "../data/opinions";

import { shuffle, parseImageUrl } from "../helpers";

const IndexPage = () => {
  const opinion = shuffle(opinions)[0].node;

  return (
    <Layout
      seo={{}}
      title="System dla przedszkoli i żłobków, aplikacja do pilnowania dzieci"
    >
      <div className="wrapper">
        <div>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="description main-desc main-page-desc">
                  <h2>
                    <span className="purple">kids</span>
                    <span className="rose">up</span> - aplikacja <br />
                    nowoczesnych przedszkoli
                  </h2>
                  <p className="bolder-desc">
                    kidsup to wyjątkowo wygodny i intuicyjny system do
                    komunikacji między przedszkolem a rodzicami przedszkolaków.
                  </p>
                  <p className="normal-desc">
                    Pilnuj zgłaszane nieobecności i bieżące opłaty, relacjonuj
                    wydarzenia w przedszkolu, prowadź zdalne nauczanie,
                    komunikuj się z rodzicami szybciej i wygodniej. Zarządzaj
                    swoim przedszkolem wygodniej, mądrzej i bezpieczniej!
                  </p>
                  <Link to={"/funkcje"}>
                    <button className="violet-btn btn desc-btn">
                      Zobacz, jak działa kidsup
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section functions functions-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-12 functions-part">
              <div>
                <img src={Talk} alt="Wygodna i bezpieczna komunikacja" />
              </div>
              <p className="fn-title">Wygodna i bezpieczna komunikacja</p>
              <p className="fn-teaser">
                Szybko i wygodnie komunikuj się z rodzicami. Uzyskaj dostęp do
                systemu z dowolnego urządzenia bez konieczności rejestracji.
                Logowanie do systemu odbywa się dzięki kodom PIN, co zwiększa
                bezpieczeństwo komunikacji.
              </p>
            </div>
            <div className="col-md-4 col-sm-12 functions-part">
              <div>
                <img src={Money} alt="Automatyczne opłaty" />
              </div>
              <p className="fn-title">Automatyczne opłaty</p>
              <p className="fn-teaser">
                Automatycznie naliczaj, pobieraj i kontroluj opłaty. Nigdy
                więcej nie będziesz musiał samodzielnie monitorować bieżących
                opłat oraz informować rodziców o zaległościach. Wszystko to
                zrobi za Ciebie kidsup.
              </p>
            </div>
            <div className="col-md-4 col-sm-12 functions-part">
              <div>
                <img src={Person} alt="Pilnowanie" />
              </div>
              <p className="fn-title">Pilnowanie obecności</p>
              <p className="fn-teaser">
                Błyskawicznie sprawdzaj i kontroluj nieobecności przedszkolaków.
                Z naszym systemem nadzorowanie obecności i podliczanie godzin
                pod koniec miesiąca będzie szybkie i pozbawione błędów.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-presentation section-place" id="place">
        <div className="section section-presentation" id="parent">
          <div className="container">
            <div className="row">
              <div className="your-kindergartner-wrapper">
                <div className="col-md-6 your-kindergartner-wrapper-col">
                  <div className="your-kindergartner">
                    <h4>Rozwiązanie szyte na miarę Twojego przedszkola</h4>
                    <p>
                      Nawet przedszkolak wie, jak ważne jest popołudniowe
                      leżakowanie. kidsup odciąży Cię od żmudnych codziennych
                      obowiązków i sprawnie je zautomatyzuje. Pozwól sobie na
                      chwilę odpoczynku i skup się na rzeczach naprawdę ważnych.
                    </p>
                    <Link to={"/opinie"}>
                      <button className="violet-btn btn join-btn">
                        Dołącz do naszych zadowolonych klientów
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="col-md-6 hidden-sm images-mac-iphone-100">
                  <img
                    className="ipad-phone-right"
                    src={IPhone}
                    alt="apka kidsup dla przedszkoli i żłobków - iPhone"
                  />
                  <img
                    className="ipad-comp additional-ipad-comp"
                    src={MacBook}
                    alt="apka kidsup dla przedszkoli i żłobków - MacBook"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        <HowWeHelp
          image={opinion.image.childImageSharp.fluid.src}
          place={opinion.place}
          author={opinion.author}
        >
          {opinion.opinion}
        </HowWeHelp>
      }

      <PurpleRow>
        Aplikacja dostosowana <br />
        do potrzeb Twojego przedszkola
      </PurpleRow>
    </Layout>
  );
};

export default IndexPage;
